require('select2');

$(function () {
    $('.select2').select2({
        theme: "bootstrap"
    });

    $('.select2-licitacao').select2({
        placeholder: 'Selecione uma Licitacao',
        ajax: {
            url: '/licitacoes',
            dataType: 'json',
            delay: 250,
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            text: item.name,
                            id: item.id
                        }
                    })
                };
            },
            cache: true
        }
    });


    $('.select2-fornecedor').select2({
        placeholder: 'Selecione um fornecedor',
        ajax: {
            url: '/fornecedores',
            dataType: 'json',
            delay: 250,
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            text: item.name,
                            id: item.id
                        }
                    })
                };
            },
            cache: true
        }
    });

    $('.select2-produto').select2({
        placeholder: 'Selecione um produto',
        ajax: {
            url: '/lista-produtos',
            dataType: 'json',
            delay: 250,
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            text: item.name,
                            id: item.id
                        }
                    })
                };
            },
            cache: true
        }
    });

    $('.select2-produto-licitacao').select2({
        placeholder: 'Selecione um produto',
        ajax: {
            url: '/lista-produtos-licitacao/' + $('.select2-produto-licitacao').attr("data-id"),
            dataType: 'json',
            delay: 250,
            processResults: function (data) {
                return {
                    results: $.map(data, function (item) {
                        return {
                            text: 'Restante: ' +   item.restam + ' ' + item.name,
                            id: item.id
                        }
                    })
                };
            },
            cache: true
        }
    });
});

