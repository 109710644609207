$(function () {

    $('[data-toggle="tooltip"]').tooltip();

    $('#licitacao_product_id').change(function () {
        var compra_id = $(this).data('compra');
        var produto_id = $(this).val();
        var input = $(this);

        $.ajax({
          url: '/produto-licitacao/' + compra_id + '/restante/' + produto_id,
          type: "GET",
        }).done(function (data) {
            alert(data)
            $('#restante').html(data.restante)
        });
    });

    $('.active').change(function () {
        var url = $(this).data('url');
        var active = $(this).is(":checked") ? 1 : 0;
        var token = $(this).data('csrf');
        var input = $(this);

        $.ajax({
          url: url,
          type: "PUT",
          data: {
            'active': active,
            '_token': token
          }
        }).done(function (data) {
          if (data == 'success') {
            // input.addClass('is-valid');
          } else {
            // input.addClass('is-invalid');
          }
        });
    });



    $("#categoria_id").change(function () {

        $("#subcategoria_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando..."
                })
            );

        $("#grupo_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando..."
                })
            );


        $.ajax({
            url: "/api/subcategorias/" + $(this).val()
        }).done(function (data) {
            $("#subcategoria_id")
                .find("option")
                .remove()
                .end();

            if (data.length > 0) {

                // $("#subcategoria_id").prop('required', true);

                $("#subcategoria_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha uma Subcategoria"
                    })
                );

                $("#grupo_id")
                    .find("option")
                    .remove()
                    .end().append(
                    $("<option>", {
                        value: "",
                        text: "Escolha uma Subcategoria"
                    })
                );

                $.each(data, function (i, item) {
                    $("#subcategoria_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.nome
                        })
                    );
                });
            } else {

                // $("#subcategoria_id").prop('required', false);

                $("#grupo_id")
                    .find("option")
                    .remove()
                    .end().append(
                    $("<option>", {
                        value: "",
                        text: "Escolha uma Subcategoria"
                    })
                );


                $("#subcategoria_id").append(
                    $("<option>", {
                        value: "",
                        text: "Categoria não possui Subcategorias"
                    })
                );
            }
        });
    });

    $("#subcategoria_id").change(function () {
        $("#grupo_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando..."
                })
            );
        $.ajax({
            url: "/api/grupos/" + $(this).val()
        }).done(function (data) {
            $("#grupo_id")
                .find("option")
                .remove()
                .end();
            if (data.length > 0) {

                // $("#grupo_id").prop('required', true);

                $("#grupo_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha um Grupo"
                    })
                );
                $.each(data, function (i, item) {
                    $("#grupo_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.nome
                        })
                    );
                });
            } else {

                // $("#grupo_id").prop('required', false);

                $("#grupo_id").append(
                    $("<option>", {
                        value: "",
                        text: "Subcategoria não possui Grupos"
                    })
                );

            }
        });
    });

    $("#estado_id").change(function () {

        const codigo_uf = $(this).val();

        $("#municipio_id")
            .find("option")
            .remove()
            .end()
            .append(
                $("<option>", {
                    value: "",
                    text: "Carregando..."
                })
            );

        $.ajax({
            url: "/api/municipios/" + codigo_uf
        }).done(function (data) {

            data = data.results;


            $("#municipio_id")
                .find("option")
                .remove()
                .end();

            if (data.length > 0) {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Escolha um Município"
                    })
                );

                $.each(data, function (i, item) {
                    $("#municipio_id").append(
                        $("<option>", {
                            value: item.id,
                            text: item.text
                        })
                    );
                });
            } else {
                $("#municipio_id").append(
                    $("<option>", {
                        value: "",
                        text: "Estado não possui Municípios"
                    })
                );
            }
        });


    });

    $(".ancora-mais-info").click(function () {
        // a animação vai ocorrer no html, body
        $("html, body").animate(
            {
                // pega o atributo href do this (link que recebeu o click)
                // e faz a animação com velocidade 1000 para o destino do href;
                scrollTop: $($(this).attr("href")).offset().top
            },
            1000
        );

        // Faz com que o destino do link não vá para a url
        event.preventDefault();
    });

    $(".btn-contato").click(function () {
        $(".btn-contato").removeClass("active");
        $(this).addClass("active");
        $(".departamentos").addClass("d-none");
        $($(this).data("target")).removeClass("d-none");
    });

    $('[data-toggle="popover"]').popover()


    $('.remover').click(function () {

        const target = $($(this).data('target'));
        const quantidade = target.val();

        if (target.val() > 1) {
            target.val(quantidade - 1);
        }

    });

    $('.adicionar').click(function () {

        const target = $($(this).data('target'));
        const quantidade = target.val() * 1;


        target.val(quantidade + 1);


    });

    $('.legenda').change(function () {

        const url = $(this).data('url');
        const legenda = $(this).val();
        const token = $(this).data('csrf');

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'subtitle': legenda,
                '_token': token,
            },
        }).done(function (data) {
            if (data == 'success') {
                input.addClass('is-valid');
            } else {
                input.addClass('is-invalid');
            }
        });

    });

    $('.legendaMultiplaPt').change(function () {

        const url = $(this).data('url');
        const legendapt = $(this).val();
        const token = $(this).data('csrf');

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'subtitlept': legendapt,
                '_token': token,
            },
        }).done(function (data) {
            if (data == 'success') {
                input.addClass('is-valid');
            } else {
                input.addClass('is-invalid');
            }
        });

    });

    $('.legendaMultiplaEn').change(function () {

        const url = $(this).data('url');
        const legendaen = $(this).val();
        const token = $(this).data('csrf');

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'subtitleen': legendaen,
                '_token': token,
            },
        }).done(function (data) {
            if (data == 'success') {
                input.addClass('is-valid');
            } else {
                input.addClass('is-invalid');
            }
        });
    });

    $('.legendaMultiplaEs').change(function () {

        const url = $(this).data('url');
        const legendaes = $(this).val();
        const token = $(this).data('csrf');

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'subtitlees': legendaes,
                '_token': token,
            },
        }).done(function (data) {
            if (data == 'success') {
                input.addClass('is-valid');
            } else {
                input.addClass('is-invalid');
            }
        });

    });

    $('.ordem').on("change", function () {

        const url = $(this).data('url');
        const ordem = $(this).val();
        const token = $(this).data('csrf');

        const input = $(this);

        $.ajax({
            url: url,
            type: "PUT",
            data: {
                'ordem': ordem,
                '_token': token,
            },
        }).done(function (data) {
            if (data == 'success') {
                input.addClass('is-valid');
            } else {
                input.addClass('is-invalid');
            }
        });
    });

    $('.btn-upload-multiplo-modal').click(function () {

        $("#uploadMultiploModal").modal("show");
        $("#uploadMultiploModal #uploadModalForm").attr("action", $(this).attr("data-href"));

        if ($(this).attr("data-multiplo")) {
            $("#uploadMultiploModal #uploadModalForm #files").prop("multiple", true);
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").prop("multiple", false);
        }

        if ($(this).attr("data-arquivo")) {
            $("#uploadMultiploModal #uploadModalForm #files").attr("data-arquivo", 1);
        } else {
            $("#uploadMultiploModal #uploadModalForm #files").attr("data-arquivo", 0);
        }


    });

    // $('body').delegate(".btn-upload-multiplo-modal", "click", function () {

    // });

    $("#uploadMultiploModal #uploadModalForm").on('submit', function () {
        $("#uploadMultiploModal .modal-body .sec-primary").hide();
        $("#uploadMultiploModal .modal-body .sec-secondary").show();
    });


    // function teste() {
    //
    //     const witdh = $(window).width();
    //     const href = $('.whatsapp').attr('href');
    //
    //     console.log(witdh, href);
    //
    //     if (witdh > 900) {
    //         $('.whatsapp').attr('href', 'https://web.whatsapp.com/send?text=' + $('.whatsapp').data('href'));
    //     } else {
    //         $('.whatsapp').attr('href', 'https://web.whatsapp.com/send?text=' + $('.whatsapp').data('href'));
    //     }
    //
    // }
    //
    // $(window).resize(teste());

});

